/**
 * 格式化日期
 * @param date 时间戳
 * @param format YYYY-MM-DD hh:mm:ss
 * @returns {boolean}
 * @constructor
 */
export const FormatDate = (date, format) => {
    if (/^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\s+(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/.test(date)) {
        date = date.replace(/-/g, "/");
    }
    const DateTime = new Date(date);
    let year = DateTime.getFullYear();
    let month = DateTime.getMonth() + 1 < 10 ? "0" + (DateTime.getMonth() + 1) : DateTime.getMonth() + 1;
    let day = DateTime.getDate() < 10 ? "0" + DateTime.getDate() : DateTime.getDate();
    let hour = DateTime.getHours() < 10 ? "0" + DateTime.getHours() : DateTime.getHours();
    let minute = DateTime.getMinutes() < 10 ? "0" + DateTime.getMinutes() : DateTime.getMinutes();
    let second = DateTime.getSeconds() < 10 ? "0" + DateTime.getSeconds() : DateTime.getSeconds();

    if (format === "YYYYMMDDhhmmss") {
        return '' + year + month + day + hour + minute + second
    }

    return format.replace(/\b[A-Za-z]+\b/g, function (word) {
        switch (word) {
            case "YYYY":
                return year;
            case "MM":
                return month;
            case "DD":
                return day;
            case "hh":
                return hour;
            case "mm":
                return minute;
            case "ss":
                return second;
            default:
                return "";
        }
    });
};

/**
 * 秒转化为分秒
 * @param number
 * @constructor
 */
export function FormatMS(number) {
    if (typeof number !== "number" || number <= 0) {
        return "--"
    }
    let m = Math.floor(number / 60);
    let s = number % 60;

    return (m < 10 ? "0" + m : m) + ":" + (s < 10 ? "0" + s : s)
}

/**
 * 根据生日获取年龄
 * @param birth
 * @returns {number}
 * @constructor
 */
export function GetAgeByBrithday(str) {
    if (!str) return "";
    var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})/);
    if (r == null) return "--";

    var d = new Date(r[1], r[3] - 1, r[4]);
    var returnStr = "输入的日期格式错误！";

    if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {

        var date = new Date();
        var yearNow = date.getFullYear();
        var monthNow = date.getMonth() + 1;
        var dayNow = date.getDate();

        var largeMonths = [1, 3, 5, 7, 8, 10, 12], //大月， 用于计算天，只在年月都为零时，天数有效
            lastMonth = monthNow - 1 > 0 ? monthNow - 1 : 12,  // 上一个月的月份
            isLeapYear = false, // 是否是闰年
            daysOFMonth = 0;    // 当前日期的上一个月多少天

        if ((yearNow % 4 === 0 && yearNow % 100 !== 0) || yearNow % 400 === 0) {  // 是否闰年， 用于计算天，只在年月都为零时，天数有效
            isLeapYear = true;
        }

        if (largeMonths.indexOf(lastMonth) > -1) {
            daysOFMonth = 31;
        } else if (lastMonth === 2) {
            if (isLeapYear) {
                daysOFMonth = 29;
            } else {
                daysOFMonth = 28;
            }
        } else {
            daysOFMonth = 30;
        }

        var Y = yearNow - parseInt(r[1]);
        var M = monthNow - parseInt(r[3]);
        var D = dayNow - parseInt(r[4]);
        if (D < 0) {
            D = D + daysOFMonth; //借一个月
            M--;
        }
        if (M < 0) {  // 借一年 12个月
            Y--;
            M = M + 12; //
        }

        /*if (Y < 0) {
            returnStr = "出生日期有误！";
        } else if (Y === 0) {
            if (M === 0) {
                returnStr = D + "D";
            } else {
                returnStr = M + "M";
            }
        } else {
            if (M === 0) {
                returnStr = Y + "Y";
            } else {
                returnStr = Y + "Y" + M + "M";
            }
        }*/

    }

    return Y;
}

/**
 * 判断是否是微信
 * @returns {boolean}
 * @constructor
 */
export function WeChat() {
    const ua = window.navigator.userAgent.toLowerCase();

    return (ua.indexOf("wechat") > -1 || ua.indexOf("micromessenger") > -1) && (ua.indexOf("mobile") > -1 || ua.indexOf("android") > -1)
}

/**
 * 获取url参数
 * @returns {{}}
 * @constructor
 */
export function GetRequest() {
    var str = window.location.href.split("?")[1]
    var theRequest = {};
    var strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
        var srtItems = strs[i].split("=");
        theRequest[srtItems[0]] = decodeURIComponent(srtItems[1]);
    }
    return theRequest;
}
